var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Client__Entity" },
    [
      _c("Entity", {
        ref: "entity",
        attrs: {
          "entity-type": "Client",
          "entity-id": _vm.clientId,
          innerComponent: _vm.innerComponent,
          innerComponentTitle: "פרטי לקוח",
          title: "לקוח ליסינג",
          "back-button-text": "רשימת לקוחות",
          "back-button-url": "clients",
          widgets: _vm.widgets,
          preview: _vm.preview,
          "read-only": _vm.readOnly,
          entityReady: _vm.entityReady,
          activities: []
        },
        on: { onFormSave: _vm.onFormSave, onFormDiscard: _vm.onFormDiscard },
        scopedSlots: _vm._u([
          {
            key: "mandatory",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "client_logo" }, [
                      _c("img", {
                        attrs: { src: _vm.Profile.ImageUrl, alt: "" }
                      })
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("שם הלקוח:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Profile.Name))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("ח.פ:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Profile.IdNumber))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("עיר:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Profile.City))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("רחוב:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Profile.Street))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("מספר בית:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Profile.HouseNumber))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("מיקוד:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Profile.ZipCode))]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("תבנית הסבה:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.Profile.HasTransferForm ? "כן" : "לא")
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [
                      _vm._v('תבנית הסבה לחתימת עו"ד:')
                    ]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.Profile.HasLawyerTransferForm ? "כן" : "לא"
                          )
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [
                      _vm._v("קיים יפוי כוח:")
                    ]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.Profile.HasPOA ? "כן" : "לא"))]
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }