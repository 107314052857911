var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ReportEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "אימייל", "read-only": _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Email,
          callback: function($$v) {
            _vm.$set(_vm.model, "Email", $$v)
          },
          expression: "model.Email"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "עמלת הסבה", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Fee,
          callback: function($$v) {
            _vm.$set(_vm.model, "Fee", $$v)
          },
          expression: "model.Fee"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "יכול לאשר דוחות לתשלום",
          filterable: false,
          clearable: false,
          options: [
            { Value: true, Text: "כן" },
            { Value: false, Text: "לא" }
          ],
          readOnly: _vm.readOnly,
          required: "true"
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.AllowsApproval,
          callback: function($$v) {
            _vm.$set(_vm.model, "AllowsApproval", $$v)
          },
          expression: "model.AllowsApproval"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "הסבות מופסקות",
          filterable: false,
          clearable: false,
          options: [
            { Value: true, Text: "כן" },
            { Value: false, Text: "לא" }
          ],
          readOnly: _vm.readOnly,
          required: "true"
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.ForbidTransfer,
          callback: function($$v) {
            _vm.$set(_vm.model, "ForbidTransfer", $$v)
          },
          expression: "model.ForbidTransfer"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "מספר פנימי", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.InternalClientId,
          callback: function($$v) {
            _vm.$set(_vm.model, "InternalClientId", $$v)
          },
          expression: "model.InternalClientId"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }