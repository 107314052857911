<template>
  <div class="Client__Entity">
    <Entity
      ref="entity"
      entity-type="Client"
      :entity-id="clientId"
      :innerComponent="innerComponent"
      innerComponentTitle="פרטי לקוח"
      title="לקוח ליסינג"
      back-button-text="רשימת לקוחות"
      back-button-url="clients"
      :widgets="widgets"
      :preview="preview"
      :read-only="readOnly"
      :entityReady="entityReady"
      :activities="[]"
      @onFormSave="onFormSave"
      @onFormDiscard="onFormDiscard"
    >
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="client_logo"
            ><img :src="Profile.ImageUrl" alt=""
          /></span>
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">שם הלקוח:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.Name }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">ח.פ:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.IdNumber }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">עיר:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.City }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">רחוב:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.Street }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מספר בית:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.HouseNumber }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מיקוד:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.ZipCode }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">תבנית הסבה:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.HasTransferForm ? "כן" : "לא" }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">תבנית הסבה לחתימת עו"ד:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.HasLawyerTransferForm ? "כן" : "לא" }}</span
          >
        </div>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">קיים יפוי כוח:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ Profile.HasPOA ? "כן" : "לא" }}</span
          >
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
import Entity from "@/components/Entity.vue";
import ClientEntityDetails from "@/components/ClientEntityDetails.vue";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import LeasingCompaniesService from "@/services/LeasingCompaniesService";
import ReportService from '../services/ReportService';
import WidgetService from "../services/WidgetService";

const { mapFields } = createHelpers({
  getterType: "client/getField",
  mutationType: "client/updateField",
});

export default {
  components: {
    Entity,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    clientId() {
      return this.$route.params.id || this.id;
    },
    widgets() {
      return [
        {
          ...WidgetService.TransferDocuments,
          namespace: "client",
          documentType: "OpeningPage",
          title: "דף ראשון בהסכם",
          hasDocument: this.data?.Client.HasOpeningPage,
        },
        {
          ...WidgetService.TransferDocuments,
          namespace: "client",
          documentType: "GeneralContractForm",
          title: "הצהרת חוכר כללית",
          hasDocument: this.data?.Client.HasGeneralContractForm,
        },
        {
          ...WidgetService.TransferDocuments,
          namespace: "client",
          documentType: "DriversLicense",
          title: "רישיון נהיגה",
          hasDocument: this.data?.Client.HasDriversLicense,
        },
        {
          ...WidgetService.TransferDocuments,
          namespace: "client",
          documentType: "ClosingPage",
          title: "טופס נוסף (דף אחרון)",
          hasDocument: this.data?.Client.HasClosingPage,
        },
        {
          ...WidgetService.Reports,
          namespace: "client",
          query: () => ({
            ProfileId: this.clientId,
            IsPaid: false,
            TransferStatus: [
              0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            ],
          }),
          noData: "אין דוחות פתוחים",
          delegate: ReportService.search
        },
        {
          ...WidgetService.VehiclesQuery,
          namespace: "client",
          query: () => ({ ProfileIds: [this.clientId] }),
        },
        {
          ...WidgetService.Reports,
          namespace: "client",
          query: () => ({ ProfileId: this.clientId, TransferStatus: [10, 11] }),
          title: "הסבות",
          noData: "אין הסבות",
        },
        {
          ...WidgetService.Reports,
          namespace: "client",
          query: () => ({
            ProfileId: this.clientId,
            DriverTransferStatus: [10, 11],
          }),
          title: "הסבות לנהגים",
          noData: "אין הסבות לנהגים",
        },
        {
          ...WidgetService.TollRoadsDrives,
          namespace: "client",
          query: () => ({ LinkedClientId: this.clientId, Status: [1] }),
          noData: "אין נסיעות כבישי אגרה",
        },
        // { ...WidgetService.ReportStatuses, namespace: 'report' },
        // { ...WidgetService.ReportAlerts, namespace: 'report' },
        // { ...WidgetService.Vehicle, namespace: 'report', fetch: true },
        // { ...WidgetService.Driver, namespace: 'report', fetch: true },
        // { ...WidgetService.Profile, namespace: 'report' },
        // { ...WidgetService.ReportDelivery, namespace: 'report' },
      ];
    },
    innerComponent() {
      return ClientEntityDetails;
    },
    ...mapFields(["Profile"]),
  },
  data() {
    return {
      entityReady: false,
    };
  },
  async created() {
    this.init();
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.clientId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      LeasingCompaniesService.updateClient(this.clientId, {
        ...this.$store.getters["client/form"],
        ...model,
      })
        .then(() => {
          this.init();
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          closeDialog();
          saving(false);
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "client/loadClient",
    }),
  },
};
</script>

<style scoped lang="scss">
.client_logo {
  width: 50px;
  display: block;
}
</style>
