<template>
    <div class="ReportEntityDetails__Component">

      <FieldInlineText
        label="אימייל"
        @onChange="onChange"
        :read-only="readOnly"
        v-model="model.Email"/>

      <FieldInlineText
        label="עמלת הסבה"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.Fee" />

      <FieldInlineSelect
        label="יכול לאשר דוחות לתשלום"
        :filterable="false"
        :clearable="false"
        :options="[{Value: true, Text: 'כן'},{Value: false, Text: 'לא'},]"
        v-model="model.AllowsApproval"
        @onChange="onChange"
        :readOnly="readOnly"
        required="true" />

      <FieldInlineSelect
        label="הסבות מופסקות"
        :filterable="false"
        :clearable="false"
        :options="[{Value: true, Text: 'כן'},{Value: false, Text: 'לא'},]"
        v-model="model.ForbidTransfer"
        @onChange="onChange"
        :readOnly="readOnly"
        required="true" />

      <FieldInlineText
        label="מספר פנימי"
        @onChange="onChange"
        :readOnly="readOnly"
        v-model="model.InternalClientId" />

    </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ClientEntityDetails',
  components: {
    FieldInlineText,
    FieldInlineSelect,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        Email: null,
        Fee: null,
        AllowsApproval: null,
        ForbidTransfer: null,
        InternalClientId: null,
      },
    };
  },
  computed: {
    ...mapGetters('client', [
      'form',
    ]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
            if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeClientDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('client', [
      'storeClientDetails',
    ]),
  },
};
</script>

<style>

</style>
