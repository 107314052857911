<template>
  <div class="Clients__View">
    <EntityListLayout :tabs="tabs" :namespace="'customers'">
      <template #title>לקוחות</template>
      <!-- <template #button>
          <b-button>הוספת לקוח חדש</b-button>
        </template> -->
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import LeasingCompaniesService from "@/services/LeasingCompaniesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import FieldService from "@/services/FieldService";
import ClientEntity from "@/views/ClientEntity.vue";

export default {
  name: "Clients",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
  },
  created() {
    const sideViewEntity = {
      component: ClientEntity,
      preview: true,
      readOnly: true,
      title: "פרטי לקוח",
      key: "ClientId",
      recordUrl: "clients",
    };
    const customersFields = FieldService.list("customers");
    this.tabs = [
      {
        id: "MyCustomers",
        title: "הלקוחות שלי",
        getDataAsync: this.getCustomers,
        fastSearch: true,
        sideViewEntity,
        fields: [
          { ...customersFields.Id, checked: true },
          { ...customersFields.IdNumber, checked: true },
          { ...customersFields.Name, checked: true },
          { ...customersFields.HasTransferForm, checked: true },
          { ...customersFields.HasOpeningPage, checked: true },
          { ...customersFields.HasGeneralContractForm, checked: true },
          { ...customersFields.HasDriversLicense, checked: true },
          { ...customersFields.Fee, checked: true },
          { ...customersFields.InternalClientId, checked: true },
          { ...customersFields.Email, checked: true },
          { ...customersFields.AllowsApproval, checked: true },
          { ...customersFields.AllowContractHtmlAggregation, checked: true },
          { ...customersFields.ForbidTransfer, checked: true },
        ],
        perPage: 20,
      },
      {
        id: "CustomersWithOpenReports",
        title: "לקוחות עם דוחות פתוחים",
        fastSearch: true,
        sideViewEntity,
        getDataAsync: this.getCustomersWithLinks,
        fields: [
          { ...customersFields.IdNumber, checked: true },
          { ...customersFields.Name, checked: true },
          { ...customersFields.InternalClientId, checked: true },
          { ...customersFields.Email, checked: true },
          { ...customersFields.AllowsApproval, checked: true },
        ],
        perPage: 20,
      },
    ];
  },
  methods: {
    getCustomers({ cancelToken, ...rest }) {
      return LeasingCompaniesService.search(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getCustomersWithLinks() {
      return LeasingCompaniesService.customersWithReports().then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getLinks({ cancelToken, ...rest }) {
      return LeasingCompaniesService.links(
        {
          ...rest,
        },
        cancelToken.token
      );
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
